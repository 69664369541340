@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --mono-100: #f7fafc;
    /* Lightest */
    --mono-200: #edf2f7;
    --mono-300: #e2e8f0;
    --mono-400: #cbd5e0;
    --mono-500: #a0aec0;
    /* Base */
    --mono-600: #718096;
    --mono-700: #4a5568;
    /* Dark */
    --mono-800: #2d3748;
    --mono-900: #1a202c;
    /* Darkest */
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Verdana', sans-serif;
}

.container {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
}

.sidebar {
    width: 200px;
    background-color: var(--mono-200);
    padding: 1rem;
}

.sidebar ul {
    list-style: none;
}

.sidebar ul li {
    cursor: pointer;
    padding: 0.5rem 0;
}

.sidebar ul li.active {
    background-color: var(--mono-300);
}

.content {
    flex-grow: 1;
    overflow-y: auto;
    background-color: var(--mono-100);
    padding: 1rem;
}

/* Card Components */
.card {
    background-color: var(--mono-100);
    border: 1px solid var(--mono-300);
    border-radius: 0.5rem;
}

.card-header {
    background-color: var(--mono-200);
    padding: 0.5rem 1rem;
}

.card-content {
    padding: 1rem;
}

/* Form Elements */
.form-input,
.form-select,
.form-textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid var(--mono-300);
    border-radius: 0.375rem;
    /* Tailwind's focus ring utilities can be used for focus states */
}

/* Buttons */
.button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--mono-700);
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    /* Add focus and hover states */
}

.button:hover {
    background-color: var(--mono-800);
}

/* Other elements */
.label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--mono-700);
}

/* TailwindCSS Overrides for Monochromatic Theme */
.bg-primary {
    background-color: var(--mono-500);
    /* Use as the primary background color */
}

.text-primary {
    color: var(--mono-700);
    /* Use as the primary text color */
}

.border-primary {
    border-color: var(--mono-300);
    /* Use as the primary border color */
}

/* Confirmation Modal */
.confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-content {
    margin-bottom: 20px;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
}